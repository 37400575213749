import styled from "styled-components";
import { transparentize } from "polished";


export const PrincipaisNoticiasStyle = styled.div`    
    .rec-slider-container {margin: 0}
    .principal-noticia__container {display: none}
    margin-bottom: 20px;


    /* Adaptação Media Query */
    ${props => props.theme.media.desktop} {
        .rec-carousel-wrapper {display: none}

        .principal-noticia__container {
            display: grid;
            grid-template-columns: 9fr 3fr;
            grid-column-gap: 32px;

            .principal-noticia__noticia-principal {
                border-radius: ${props => props.theme.borderRadius};
                width: 100%;
                height: 528px;
                position: relative;

                img {
                    border-radius: ${props => props.theme.borderRadius};
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                }

                .carousel-item__category {
                    background-color: ${props => props.theme.colors.primary};
                    border-radius: ${props => props.theme.borderRadius};
                    font-size: ${props => props.theme.fontSize.small};
                    color: ${props => props.theme.colors.textWhite};
                    font-weight: 500;
                    padding: 2px 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 14px;
                    left: 14px;
                }
                
                .carousel-item__category--not-default {
                    background-color: ${props => props.theme.colors.secondary};
                }

                .carousel-item__text {
                    background: ${props => transparentize(0.30, props.theme.colors.primary)};
                    border-bottom-left-radius: ${props => props.theme.borderRadius};
                    border-bottom-right-radius: ${props => props.theme.borderRadius};
                    width: 100%;
                    height: 122px;
                    padding: 0px 35px 0px 21px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    .carousel-item__text__title a {
                        color: ${props => props.theme.colors.textWhite};
                        font-size: ${props => props.theme.fontSizeDesktop.large};
                        font-weight: 500;

                        text-decoration:none;


                        &:hover{
                            text-decoration:underline
                        }
                    }

                    .carousel-item__text__saiba-mais a {
                        color: ${props => props.theme.colors.textWhite};
                        font-size: ${props  => props.theme.fontSizeDesktop.small};
                        margin-top: 8px;
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        text-decoration: none;
                  
                        svg {
                            font-size: 22px;
                            margin-left: 6px;
                        }
                    }
                }
            }

            .principal-noticia__noticias-secundarias {
                display: grid;
                grid-template-columns: 1fr;
                grid-row-gap: 20px;                

                div {
                    border-radius: ${props => props.theme.borderRadius};
                    width: 100%;
                    position: relative;

                    img {
                        border-radius: ${props => props.theme.borderRadius};                        
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;                        
                    }

                    .carousel-item__category {
                     
                       
                        width: auto;
                       
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 14px;
                        left: 14px;
                    }
                    .carousel-item__category p {
                        background-color: ${props => props.theme.colors.primary};
                        margin-right:1rem;
                        padding: 2px 8px;
                        border-radius: ${props => props.theme.borderRadius};
                        font-size: ${props => props.theme.fontSize.small};
                        color: ${props => props.theme.colors.textWhite};
                        font-weight: 500;
                    }
                    .carousel-item__category--not-default {
                        background-color: ${props => props.theme.colors.secondary};
                    }

                    .carousel-item__text {
                        background-color: ${props => transparentize(0.30, props.theme.colors.primary)};
                        width: 100%;
                        height: 123px;
                        padding: 0px 38px 0px 14px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        .carousel-item__text__title h3 {
                            color: ${props => props.theme.colors.textWhite};
                            font-size: ${props => props.theme.fontSize.large};
                            font-weight: 500;
                            margin-top: 6px;
                        }

                        .carousel-item__text__saiba-mais a {
                            color: ${props => props.theme.colors.textWhite};
                            font-size: ${props  => props.theme.fontSize.small};
                            margin-top: 8px;
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            text-decoration: none;
                           
                            svg {
                                font-size: 18px;
                                margin-left: 6px;
                            }
                          
                        }
                    }

                    .carousel-item__text--not-default {
                        background-color: ${props => transparentize(0.30, props.theme.colors.secondary)};
                    }
                }
            }
        }
    }
`;

export const CarouselItem = styled.div`
    width: 100%;
    height: 216px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;        
    }

    .carousel-item__category {
        background-color: ${props => props.theme.colors.primary};
        border-radius: ${props => props.theme.borderRadius};
        font-size: ${props => props.theme.fontSize.small};
        color: ${props => props.theme.colors.textWhite};
        font-weight: 500;
        width: 80px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 10px;
        left: 45px;
    }

    .carousel-item__category--not-default {
        background-color: ${props => props.theme.colors.secondary};
    }

    .carousel-item__text {
        background: ${props => transparentize(0.30, props.theme.colors.primary)};        
        width: 100%;
        height: 76px;
        padding: ${props => props.theme.margin};
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;        

        .carousel-item__text__title h3 {
            color: ${props => props.theme.colors.textWhite};
            font-size: ${props => props.theme.fontSize.large};
            font-weight: 500;
            margin-top: 6px;
        }

        .carousel-item__text__saiba-mais a {
            color: ${props => props.theme.colors.textWhite};
            font-size: ${props  => props.theme.fontSize.small};
            margin-top: 8px;
            display: flex;
            align-items: center;
            font-weight: 500;
            text-decoration: none;

            svg {
                font-size: 16px;
                margin-left: 8px;
            }
        }
    }

    .carousel-item__text--not-default {
        background-color: ${props => transparentize(0.30, props.theme.colors.secondary)};
    }
`;