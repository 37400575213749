import styled from "styled-components";
import { transparentize } from "polished";

export const ProgramacaoEventosStyle = styled.div`    
    .rec-slider-container {margin: 0}
    .programacao-eventos__container {display: none}
    margin-bottom: 20px;

    .programacao-eventos__title {        
        margin: ${props => props.theme.margin};
        height: 25px;

        .programacao-eventos__title--text {
            border-bottom: 2px solid ${props => props.theme.colors.primary};
            width: 220px;
            height: 25px;
            
            p {
                font-size: ${props => props.theme.fontSize.large};
                color: #4D4D4D;
                font-weight: 600;
            }
        }
    }


    /* Adaptação Media Query */
    ${props => props.theme.media.desktop} {
        .rec-carousel-wrapper {display: none}

        .programacao-eventos__title {
            margin: 15px 0px;

            .programacao-eventos__title--text {
                width: 150px;
                
                p {
                    font-size: ${props => props.theme.fontSizeDesktop.large};
                    position: absolute;
                }
            }
        }

        .programacao-eventos__container {
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: 32px;            

            .programacao-eventos__cards {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 20px;
                grid-row-gap: 20px;

                .programacao-eventos__card {
                    border-radius: ${props => props.theme.borderRadius};
                    height: 235px;
                    max-width:400px;
                    .programacao-eventos__card__image {
                        border-top-right-radius: ${props => props.theme.borderRadius};
                        border-top-left-radius: ${props => props.theme.borderRadius};
                        max-width: 100%;
                        height: 170px;                        

                        img {
                            border-top-right-radius: ${props => props.theme.borderRadius};
                            border-top-left-radius: ${props => props.theme.borderRadius};
                            width: 100%;
                            height: 170px;
                        }
                    }

                    .programacao-eventos__card__text {
                        background-color: ${props => props.theme.colors.secondary};
                        border-bottom-right-radius: ${props => props.theme.borderRadius};
                        border-bottom-left-radius: ${props => props.theme.borderRadius};
                        padding: 6px 16px;
                        height: 66px;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;

                        .programacao-eventos__card__text__title h3 {
                            font-size: ${props => props.theme.fontSizeDesktop.large};
                            color: ${props => props.theme.colors.textWhite};
                            margin-bottom: 6px;
                        }

                        .programacao-eventos__card__text__description {
                            display: flex;
                            align-items: center;

                            svg {
                                color: ${props => props.theme.colors.textWhite};
                                font-size: 20px;
                                margin-right: 10px;
                            }

                            h4 {
                                font-size: ${props => props.theme.fontSizeDesktop.medium};
                                color: ${props => props.theme.colors.textWhite};
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const CarouselItem = styled.div`
    width: 100%;
    height: 156px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    .carousel-item__text {
        background: ${props => transparentize(0.30, props.theme.colors.secondary)};
        padding: ${props => props.theme.margin};
        width: 100%;
        height: 61px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        .carousel-item__text__title h3 {
            color: ${props => props.theme.colors.textWhite};
            font-size: ${props => props.theme.fontSize.large};
            font-weight: 600;
            margin-top: 8px;
        }

        .carousel-item__text__description {
            display: flex;
            align-items: center;
            margin-top: 6px;

            svg {
                color: ${props => props.theme.colors.textWhite};
                font-size: 20px;
                margin-right: 10px;
            }

            h4 {
                color: ${props => props.theme.colors.textWhite};
                font-size: ${props => props.theme.fontSize.medium};
                font-weight: 400;
            }
        }
    }
`;