import styled from "styled-components";

export const ContatoStyle = styled.section`
  padding: 55px 0px;
  background-image: url("/images/footer/onda1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 6%;
  padding-bottom: 9rem;
  .contato__container {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .home__default__text {
      text-align: center;

      .home__default__title {
       

        h2 {
          color: ${(props) => props.theme.colors.textWhite};
        }
      }
    }
  }
  .contato__form {
    width: 100%;
    max-width: 700px;
  }

  /* Adaptação Media Query */
  ${(props) => props.theme.media.desktop} {
    .contato__container {
      .home__default__text {
        .home__default__title {
          width: 100%;
          h2 {
            width: 570px;
          }
        }
      }
    }
  }
`;
