import styled from "styled-components";

export const NoticiasMaisLidasStyle = styled.aside`    
    margin: ${props => props.theme.margin};

    > * {
        background-color: white;
        border: 1px solid ${props => props.theme.colors.border};
    }

    .noticias__mais-lidas__title {
        border-top-right-radius: ${props => props.theme.borderRadius};
        border-top-left-radius: ${props => props.theme.borderRadius};
        padding: 10px 15px;

        h3 {font-size: ${props  => props.theme.fontSize.medium}}
    }

    .noticias__mais-lidas__text {
        padding: 10px 15px;

        a {
            color: ${props => props.theme.colors.textBlack};
            font-size: ${props  => props.theme.fontSize.medium};
            font-weight: 500;
            text-decoration:none;

            &:hover{
                text-decoration:underline;

            }



        }

        &:last-child {
            border-bottom-right-radius: ${props => props.theme.borderRadius};
            border-bottom-left-radius: ${props => props.theme.borderRadius};
        }
    }


    /* Adaptação Media Query */
    ${props => props.theme.media.desktop} {
        margin: 0px 0px 10px;        
    }
`;