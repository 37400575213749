import ConhecaAquabit from "components/Cards/ConhecaAquabit/ConhecaAquabit";


// Styles
import { HomeStyle } from "./styles";
import { Container } from "styles/Global";
import Cards from "./Cards/Cards";


const FAQ: React.FC = () => {



  return (
    <HomeStyle>
      <Container className="home__container">
        {/* Anúncio Aquabit */}
        <ConhecaAquabit />

        {/* Campo de busca */}
        <Cards />

   
      </Container>
    </HomeStyle>
  );
};

export default FAQ;
