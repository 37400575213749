// Styles
import { CategoriasStyle } from "./styles";

export default function Categorias({ category }: any) {
  return (
    <CategoriasStyle>
      <div className="tags__title">
        <h4>Categorias</h4>
      </div>

      <div className="tags__container">
        {Object.keys(category).length > 0
          ? category.results.map((categoria: Categorias) => (
              <button
              key={categoria.id}
                className={
                  categoria.nome === "Carcinicultura"
                    ? "tags__carcinicultura"
                    : ""
                }
              >
                {categoria.nome}
              </button>
            ))
          : null}
      </div>
    </CategoriasStyle>
  );
}
