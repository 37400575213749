// Styles
import { ButtonStyle } from "./styles";

// Icons
import { MdArrowRightAlt } from 'react-icons/md';

// Interfaces
interface IProps {
    title: string;
    link: string;
}


const Button: React.FC<IProps> = (props) => {
    return (
        <ButtonStyle>
            <a href={props.link} target="_blank" rel="noreferrer">
                {props.title}
                <MdArrowRightAlt />
            </a>
        </ButtonStyle>
    )
}

export default Button;