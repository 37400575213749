
import {
  PrincipaisNoticiasStyle,

  ComponentsInnerHtml,
} from "./styles";
import DOMPurify from "dompurify";
// Icons


function Noticia({ notices }: any) {
  const seneteize =
    Object.keys(notices).length > 0 ? DOMPurify.sanitize(notices.body.replaceAll("\r\n\r\n", "<br><br>")) : "";
  return (
    <PrincipaisNoticiasStyle>
      {/* Notícias para Mobile */}
      {/* <Carousel itemsToShow={1} isRTL={false} showArrows={false}>
        {Object.keys(notices).length > 0
          ? notices.results.map((noticia: Noticias) => (
              <CarouselItem key={noticia.id}>
                <img src={noticia.img} alt="Teste" />

                <div className={"carousel-item__category"}>
                  {noticia.categoria.map((categoria: any) => {
                    <p
                      className={
                        categoria.nome === "Carcinicultura"
                          ? "carousel-item__category--not-default"
                          : ""
                      }
                    >
                      {categoria.nome}
                    </p>;
                  })}
                </div>

                <div className={"carousel-item__text"}>
                  <div className="carousel-item__text__title">
                    <h3>{noticia.titulo}</h3>
                  </div>

                  <div className="carousel-item__text__saiba-mais">
                    <a href={""}>
                      SAIBA MAIS
                      <MdArrowRightAlt />
                    </a>
                  </div>
                </div>
              </CarouselItem>
            ))
          : null}
      </Carousel> */}

      {/* Notícias para Desktop */}
      {Object.keys(notices).length > 0 ? (
        <div className="principal-noticia__container">
          <div className="carousel-item__text__title">
            <h3>{notices.titulo}</h3>
          </div>
          <img src={notices.img} alt="" />

          <ComponentsInnerHtml
            dangerouslySetInnerHTML={{
              __html: seneteize,
            }}
          ></ComponentsInnerHtml>
        </div>
      ) : null}
    </PrincipaisNoticiasStyle>
  );
}

export default Noticia;
