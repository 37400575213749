import Carousel from "react-elastic-carousel";

// Styles
import { ProgramacaoEventosStyle, CarouselItem } from "./styles";

// Icons
import { RiCalendarEventLine } from "react-icons/ri";

function ProgamacaoEventos({eventos}: any) {




  return (
    <ProgramacaoEventosStyle>
      <div className="programacao-eventos__title">
        <div className="programacao-eventos__title--text">
          <p>Programação de eventos</p>
        </div>
      </div>

      {/* Programação e Eventos para Mobile */}
      <Carousel itemsToShow={1} isRTL={false} showArrows={false}>
        {Object.keys(eventos).length > 0
          ? eventos.results.map((evento: ProgramacaoEventos) => (
              <CarouselItem key={evento.id}>
                <img src={evento.img} alt="" />

                <div className="carousel-item__text">
                  <div className="carousel-item__text__title">
                    <h3>{evento.titulo}</h3>
                  </div>

                  <div className="carousel-item__text__description">
                    <RiCalendarEventLine />
                    <h4 dangerouslySetInnerHTML={{ __html: evento.body }} />
                  </div>
                </div>
              </CarouselItem>
            ))
          : null}
      </Carousel>

      {/* Programação e Eventos para Desktop */}
      <div className="programacao-eventos__container">
        <div className="programacao-eventos__cards">
          {Object.keys(eventos).length > 0
            ? eventos.results.map((evento: ProgramacaoEventos) => (
                <div className="programacao-eventos__card">
                  <div className="programacao-eventos__card__image">
                    <img src={evento.img} alt={evento.titulo} />
                  </div>

                  <div className="programacao-eventos__card__text">
                    <div className="programacao-eventos__card__text__title">
                      <h3>{evento.titulo.substring(0,40)}....</h3>
                    </div>


                    <div className="programacao-eventos__card__text__description">
                      <RiCalendarEventLine />
                      <h4>
                        {`${evento.data.substring(11,13)}/${evento.data.substring(8,10)}/${evento.data.substring(0,4)}`}
                      </h4>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </ProgramacaoEventosStyle>
  );
}

export default ProgamacaoEventos;
