import { useState } from "react";
import Input from "react-phone-number-input/input";

// Toast envio do email
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

// Styles
import { FormStyle } from "./styles";

// Icons
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const Form: React.FC = () => {
  const estadosBrasil = require("services/estados-brasil.json");

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");

  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");

  const enviarEmail = async () => {
    if (nome && email && telefone && estado && cidade) {
      toast.success("Email enviado!");
      setNome("");
      setEmail("");
      setTelefone("");
      setEstado("");
      setCidade("");
    } else {
      toast.warning("Preencha o formulario corretamente!");
    }
  };

  return (
    <>
      <FormStyle>
        <input
          type="text"
          placeholder="Nome"
          value={nome}
          onChange={(e: any) => {
            setNome(e.target.value);
          }}
        />

        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
        />

        <Input
          placeholder="WhatsApp"
          defaultCountry="BR"
          value={telefone}
          onChange={(e: any) => {
            setTelefone(e);
          }}
        />

        <div className="form__input__localization">
          <select
            className="grid-select-estado"
            name="select-estado"
            defaultValue={""}
            onChange={(e: any) => {
              setEstado(e.target.value);
            }}
          >
            <option value="" disabled hidden>
              Estado
            </option>

            {estadosBrasil.map((estado: Estado, idx: number) => (
              <option key={idx} value={estado.sigla}>
                {estado.nome}
              </option>
            ))}
          </select>

          <input
            className="grid-cidade"
            type="text"
            placeholder="Cidade"
            value={cidade}
            onChange={(e: any) => {
              setCidade(e.target.value);
            }}
          />
        </div>

        <div className="form__button">
          <button
            type="button"
            id="botao-form"
            onClick={() => {
              enviarEmail();
            }}
          >
            ENVIAR
            <MdOutlineKeyboardArrowRight />
          </button>
        </div>
      </FormStyle>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </>
  );
};

export default Form;
