import styled from "styled-components";

export const HomeStyle = styled.div`
    .home__propaganda {
        margin: ${props => props.theme.margin};

        .home__propaganda__text-container {
            .home__propaganda__text {
                * {color: ${props => props.theme.colors.primary}}
                h1 {font-size: 76px}
                h2 {font-size: 18px}
            }

            .home__propaganda__computer {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10px 0px;
            }

            .home__propaganda__description {            
                margin: 20px 0px;

                p {
                    font-size: ${props => props.theme.fontSize.medium};
                    color: ${props => props.theme.colors.primary}
                }
            }
        }
        
        .home__propaganda__image-container {display: none}
    }


    /* Adaptação Media Query */
    ${props => props.theme.media.desktop} {
        .home__propaganda {
            height: 790px;
            margin-top: 60px;
            display: flex;
            align-items: center;
            margin: 75px 0px 95px 0px;

            .home__propaganda__text-container {
                width: 45%;

                .home__propaganda__text {
                    h1 {font-size: 105px}
                    h2 {font-size: 32px}
                }

                .home__propaganda__computer {margin: 25px 0px}

                .home__propaganda__description p {font-size: 18px}
            }

            .home__propaganda__image-container {
                display: flex;
                width: 660px;
                right: 0;

                img {width: 100%}
            }
        }
    }
`;

export const ContentWithAside = styled.div`
    display: grid;
    grid-template-columns: 1fr;


    /* Adaptação Media Query */
    ${props => props.theme.media.desktop} {        
        grid-template-columns: 9fr 3fr;
        grid-column-gap: 30px;
    }
`;