
import { ArtigosCientificosStyle } from "./styles";

// Icons
import { MdArrowRightAlt } from "react-icons/md";

function ArtigosCientificos({ artigues }: any) {
  return (
    <ArtigosCientificosStyle>
      <div className="artigos-cientificos__title">
        <div className="artigos-cientificos__title--text">
          <p>Artigo científico da aquicultura</p>
        </div>
      </div>

      <div className="artigos-cientificos__container">
        {Object.keys(artigues).length > 0
          ? artigues.results.slice(0, 3).map((artigo: ArtigosCientificos) => (
              <div key={artigo.id} className="artigos-cientificos__individual">
                <div className="artigos-cientificos__text">
                  <div className="artigos-cientificos__text__title">

                  <a href={`/artigo/${artigo.id}`}>{artigo.titulo.substring(0, 30)}</a>
                
                  </div>

                  <div className="artigos-cientificos__text__description">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: artigo.resumo.substring(0, 120),
                      }}
                    />
                  </div>

                  <div
                    className={
                      artigo.categoria
                        ? "artigos-cientificos__text__buttons artigos-cientificos__text__buttons--not-default"
                        : "artigos-cientificos__text__buttons"
                    }
                  >
                    {artigo.categoria.length > 0
                      ? artigo.categoria.map((item) => {
                          return (
                            <p
                              className={
                                item.nome === "Carcinicultura"
                                  ? "artigos-cientificos__text__buttons--not-default"
                                  : ""
                              }
                            >
                              {item.nome.substring(0, 12)}
                            </p>
                          );
                        })
                      : null}

                    <a href={`/artigo/${artigo.id}`}>
                      SAIBA MAIS
                      <MdArrowRightAlt />
                    </a>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>

      {/* <Button title="SAIBA MAIS" link="https://www.google.com" /> */}
    </ArtigosCientificosStyle>
  );
}

export default ArtigosCientificos;
