import styled from "styled-components";

export const SearchStyle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    > * {
        height: 42px;
        border: none;
        background-color: #ECECEC;
    }

    input {
        width: 85%;
        padding: 20px;
        outline: none;

        &:focus {outline: none}
    }

    button {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 19px;
        color: ${props => props.theme.colors.primary};
        outline: none;
        cursor: pointer;

        &:focus {outline: none}
    }


    /* Adaptação Media Query */
    ${props => props.theme.media.desktop} {
        input {
            width: 94%;
            border-top-left-radius: ${props => props.theme.borderRadius};
            border-bottom-left-radius: ${props => props.theme.borderRadius};
        }

        button {
            width: 6%;
            border-top-right-radius: ${props => props.theme.borderRadius};
            border-bottom-right-radius: ${props => props.theme.borderRadius};
            color: ${props => props.theme.colors.secondary};
        }
    }
`;