import ConhecaAquabit from "components/Cards/ConhecaAquabit/ConhecaAquabit";
import Search from "components/Search/Search";
import NoticiasMaisLidas from "components/Cards/NoticiasMaisLidas/NoticiasMaisLidas";
import Categorias from "components/Cards/Categorias/Categorias";
import ArtigosCientificos from "components/Cards/ArtigosCientifcos/ArtigosCientificos";
import { useParams } from "react-router-dom";
// Styles
import { HomeStyle, ContentWithAside } from "./styles";
import { Container } from "styles/Global";
import { apiGet } from "api/api";
import { useEffect, useState } from "react";
import Noticia from "components/Cards/Noticia/Noticia";
import ProgramacaoEventosSideMenu from "components/Cards/ProgramacaoEventosSideMenu/ProgramacaoEventosSideMenu";

const NoticesPage: React.FC = () => {
  const [category, setCategory] = useState({} as any);
  const [notices, setNotices] = useState({} as any);
  const [notice, setNotice] = useState({} as any);
  const [artigues, setArtigues] = useState({} as any);
  const [events, setEvents] = useState({} as any);
  let { id } = useParams();
  async function SearchNotice() {
    const res = await apiGet(`https://ead-api.aquabitfarm.com/api/v1/noticias/${id}`);
    setNotice(res);
  }
  async function SearchNotices() {
    const res = await apiGet(`https://ead-api.aquabitfarm.com/api/v1/noticias/`);
    setNotices(res);
  }
  async function SearchCategories() {
    const res = await apiGet("https://ead-api.aquabitfarm.com/api/v1/categorias/");
    setCategory(res);
  }
  async function Searchtags() {
    // const res = await apiGet("https://ead-api.aquabitfarm.com/api/v1/tags/");
    // setTags(res);
  }
  async function SearchArtigues() {
    const res = await apiGet("https://ead-api.aquabitfarm.com/api/v1/artigos/");
    setArtigues(res);
  }
  async function SearchEvents() {
    const res = await apiGet("https://ead-api.aquabitfarm.com/api/v1/eventos/");
    setEvents(res);
  }

  useEffect(() => {
    SearchEvents();
    SearchArtigues();
    Searchtags();
    SearchCategories();
    SearchNotices();
    // eslint-disable-next-line
    SearchNotice();
    // eslint-disable-next-line
  }, [notice, setNotice]);

  return (
    <HomeStyle>
      <Container className="home__container">
        {/* Anúncio Aquabit */}
        <ConhecaAquabit />

        {/* Campo de busca */}
        <Search />

        {/* 3 notícias, Notícias mais lidas e tags */}
        <ContentWithAside>
          <Noticia notices={notice} />

          <aside>
            <Categorias category={category} />
            <br />
            <NoticiasMaisLidas notices={notices} />
            <br />
            <ArtigosCientificos artigues={artigues} />
            <br />
            <ProgramacaoEventosSideMenu eventos={events} />
          </aside>
        </ContentWithAside>

        {/* Anúncio Aquabit */}
        <ConhecaAquabit />
      </Container>
    </HomeStyle>
  );
};

export default NoticesPage;
