// Styles
import { NoticiasStyle } from "./styles";

// Icons
import { MdArrowRightAlt } from "react-icons/md";

function CardNoticias({ notices }: any) {
  return (
    <NoticiasStyle>
      <div className="noticias__container">
        {Object.keys(notices).length
          ? notices.results.slice(3, 7).map((noticia: Noticias) => (
              <div key={noticia.id} className="noticias__individual">
                <div className="noticias__image">
                  {noticia.img ? (
                    <img src={noticia.img} alt={noticia.titulo} />
                  ) : null}
                </div>

                <div className="noticias__text">
                  <div className="noticias__text__title">
                    <a href={`/noticia/${noticia.id}`}>{noticia.titulo}</a>
                  </div>

                  <div className="noticias__text__description">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: noticia.resumo.substring(0, 259) + "...",
                      }}
                    />
                  </div>

                  <div className={"noticias__text__buttons"}>
                    {noticia.categoria.map((categoria) => {
                      return (
                        <p
                          className={
                            categoria.nome === "Carcinicultura"
                              ? "noticias__text__buttons--not-default"
                              : ""
                          }
                        >
                          {categoria.nome}
                        </p>
                      );
                    })}

                    <a href={`/noticia/${noticia.id}`}>
                      SAIBA MAIS
                      <MdArrowRightAlt />
                    </a>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </NoticiasStyle>
  );
}

export default CardNoticias;
