import { Link } from "react-router-dom";

// Styles
import { HeaderStyle } from "./styles";
import { Container } from "styles/Global";

// Icons
import { BiMenu } from 'react-icons/bi';


const Header: React.FC = () => {
    return (
        <HeaderStyle>
            <Container className="header__container">
                <div className="header__title">
                    <Link to="/">
                        <h1>AquaLearn</h1>
                    </Link>
                </div>

                <div className="header__menu">
                    <div className="header__mobile-menu">
                        <BiMenu />
                    </div>

                    <nav>
                        <ul>
                            {/* <li><Link to="/categorias">Categorias</Link></li>
                            
                            <li><Link to="/eventos">Eventos</Link></li>

                            <li><Link to="/conheca-aquabit">Conheça Aquabit</Link></li> */}
                            <li><Link to="/faq">FAQ</Link></li>
                        </ul>
                    </nav>
                </div>
            </Container>
        </HeaderStyle>
    )
}

export default Header;