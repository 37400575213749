import styled from "styled-components";

export const CategoriasStyle = styled.div`
    margin: ${props => props.theme.margin};
    margin-bottom: 45px;

    > * {
        background-color: white;
        border: 1px solid ${props => props.theme.colors.border};
    }

    .tags__title {
        border-top-right-radius: ${props => props.theme.borderRadius};
        border-top-left-radius: ${props => props.theme.borderRadius};
        padding: 10px 15px;

        h3 {font-size: ${props  => props.theme.fontSize.medium}}
    }

    .tags__container {
        background-color: white;
        border-bottom-right-radius: ${props => props.theme.borderRadius};
        border-bottom-left-radius: ${props => props.theme.borderRadius};
        padding: 10px 15px;

        button {
            border: 2px solid ${props => props.theme.colors.primary};
            color: ${props => props.theme.colors.primary};
            border-radius: ${props => props.theme.borderRadius};
            font-family: 'Roboto';
            font-weight: 500;
            background-color: transparent;
            width: auto;
            height: auto;
            padding: 2px 6px;
            margin: 0px 4px 8px 0px;
            cursor: pointer;
            
            &:hover {
                background-color: ${props => props.theme.colors.primary};
                color: ${props => props.theme.colors.textWhite};
            }
        }

        // Botão carnicicultura
        .tags__carcinicultura {
            border: 2px solid ${props => props.theme.colors.secondary};
            color: ${props => props.theme.colors.secondary};

            &:hover {
                background-color: ${props => props.theme.colors.secondary};                
            }
        }
    }


    /* Adaptação Media Query */
    ${props => props.theme.media.desktop} {
        margin: 0px 0px 10px 0px;
    }
`;