import styled from "styled-components";
import { shade } from "polished";

export const ButtonStyle = styled.div`
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: ${props => props.theme.colors.textWhite};
        font-size: ${props  => props.theme.fontSize.medium};
        background-color: ${props => props.theme.colors.secondary};
        border-radius: ${props => props.theme.borderRadius};
        transition: ${props => props.theme.transition};
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        text-decoration: none;
        width: 145px;
        height: 35px;

        &:hover {
            background-color: ${props => shade(0.2, props.theme.colors.secondary)};
        }

        svg {
            font-size: 16px;
            margin-left: 6px;
        }
    }


    /* Adaptação Media Query */
    ${props => props.theme.media.desktop} {
        margin: 15px 0px 15px 0px;

        a {
            width: 158px;
            height: 40px;
            font-size: 16px;                

            svg {
                font-size: 18px;
            }
        }
    }
`;