import styled from "styled-components";
import { transparentize } from "polished";

export const PrincipaisNoticiasStyle = styled.div`
  margin-bottom: 20px;
  width: 90%;
  
  .rec-carousel-wrapper {
    display: none;
  }

  .principal-noticia__container {
    display: flex;
    flex-direction: column;
    width:80%
    img {
      border-radius: ${(props) => props.theme.borderRadius};
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 2rem 0;
    }

    .carousel-item__text__title h3 {
      color: ${(props) => props.theme.colors.textBlack};
      font-size: ${(props) => props.theme.fontSizeDesktop.title};
      font-weight: 500;
    }
  }
`;

export const CarouselItem = styled.div`
  width: 100%;
  height: 216px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .carousel-item__category {
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: ${(props) => props.theme.borderRadius};
    font-size: ${(props) => props.theme.fontSize.small};
    color: ${(props) => props.theme.colors.textWhite};
    font-weight: 500;
    width: 80px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: 45px;
  }

  .carousel-item__category--not-default {
    background-color: ${(props) => props.theme.colors.secondary};
  }

  .carousel-item__text {
    background: ${(props) => transparentize(0.3, props.theme.colors.primary)};
    width: 100%;
    height: 76px;
    padding: ${(props) => props.theme.margin};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .carousel-item__text__title h3 {
      color: ${(props) => props.theme.colors.textWhite};
      font-size: ${(props) => props.theme.fontSize.large};
      font-weight: 500;
      margin-top: 6px;
    }

    .carousel-item__text__saiba-mais a {
      color: ${(props) => props.theme.colors.textWhite};
      font-size: ${(props) => props.theme.fontSize.small};
      margin-top: 8px;
      display: flex;
      align-items: center;
      font-weight: 500;
      text-decoration: none;

      svg {
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }

  .carousel-item__text--not-default {
    background-color: ${(props) =>
      transparentize(0.3, props.theme.colors.secondary)};
  }
`;

export const ComponentsInnerHtml = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content:space-between * {
    margin-top: 1rem !important;
  }
`;
