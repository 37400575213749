// Styles
import { EventosStyle } from "./styles";
import { Container } from "styles/Global";


const Eventos: React.FC = () => {
    return (
        <EventosStyle>
            <Container>
                Eventos!
            </Container>
        </EventosStyle>
    )
}

export default Eventos;