import styled from "styled-components";

export const HeaderStyle = styled.header`
    width: 100%;
    height: 59px;
    background-color: ${props => props.theme.colors.primary};    
    padding: 0px 15px;

    .header__container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;        

        .header__title {
            a {
                text-decoration: none;

                h1 {
                    color: ${props => props.theme.colors.textWhite};
                    font-size: ${props => props.theme.fontSize.title};
                }
            }
        }

        .header__menu {
            .header__mobile-menu {
                display: flex;
                color: ${props => props.theme.colors.textWhite};
                font-size: ${props => props.theme.fontSize.title};
            }

            nav ul {display: none}
        }
    }


    /* Adaptação Media Query */
    ${props => props.theme.media.desktop} {
        .header__container {
            .header__menu {
                .header__mobile-menu {display: none}

                nav ul {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    li {
                        padding-left: 10px;
                        list-style: none;

                        a {
                            padding: 5px 10px;
                            text-decoration: none;
                            color: ${props => props.theme.colors.textWhite};
                            font-size: ${props => props.theme.fontSizeDesktop.medium};
                        }
                    }
                }
            }
        }
    }
`;