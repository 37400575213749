
export async function apiGet(apiUrl: string) {
  let res = {}

  await fetch(apiUrl, {
    method: "GET", // or 'POST', 'PUT', 'DELETE', etc.
    headers: {
      Authorization: "Bearer YourAccessToken", // If needed
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      res =  response.json();
    })
    .then((data) => {
    })
    .catch((error) => {
      console.error("Fetch error:", error);
    });

    return res
}
