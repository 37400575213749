export const DefaultTheme = {
    title: 'DefaultTheme',

    colors: {
        primary: '#1C58CD',
        secondary: '#EC7722',

        background: '#F9F9F9',
        
        textWhite: '#FFFFFF',
        textBlack: '#555555',

        border: '#E6E6E6'
    },

    fontSize: {
        small: '12px',
        medium: '14px',
        large: '16px',
        title: '26px'
    },

    borderRadius: '4px',
    transition: 'all .2s',
    margin: '0px 45px 10px 45px',

    // Media Query
    media: {
        desktop: '@media(min-width: 1216px)'
    },

    fontSizeDesktop: {
        small: '14px',
        medium: '16px',
        large: '18px',
        title: '30px'
    },
}