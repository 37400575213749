import { BrowserRouter, Routes as Switch, Route } from "react-router-dom";

// Pages
import Home from "pages/Home/Home";
import Categorias from "pages/Categorias/Categorias";

import Eventos from "pages/Eventos/Eventos";
import ConhecaAquabit from "pages/ConhecaAquabit/ConhecaAquabit";

// Styles
import Layout from "components/Layout";
import NoticesPage from "pages/NoticesPage/NoticesPage";
import ArtiguesPage from "pages/ArtiguesPage/ArtiguesPage";
import Faq from "pages/Faq/FAQ";

const Routes = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path="/" element={<Home />} />
          <Route path="/categorias" element={<Categorias />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/noticia/:id" element={<NoticesPage />} />
          <Route path="/artigo/:id" element={<ArtiguesPage />} />
          <Route path="/eventos" element={<Eventos />} />
          <Route path="/conheca-aquabit" element={<ConhecaAquabit />} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default Routes;
