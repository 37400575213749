import styled from "styled-components";

export const ArtigosCientificosStyle = styled.div`
    .artigos-cientificos__title {        
        margin: ${props => props.theme.margin};
        height: 25px;

        .artigos-cientificos__title--text {
            border-bottom: 2px solid ${props => props.theme.colors.primary};
            width: 265px;
            height: 25px;
            
            p {
                font-size: ${props => props.theme.fontSize.large};
                color: #4D4D4D;
                font-weight: 600;
            }
        }
    }

    .artigos-cientificos__container {
        margin-bottom: 20px;

        .artigos-cientificos__individual {
            border-radius: ${props => props.theme.borderRadius};
            border: 1px solid ${props => props.theme.colors.border};
            margin: ${props => props.theme.margin};
            background-color: white;
            padding: 15px;

            .artigos-cientificos__image {display: none}

            .artigos-cientificos__text {
                .artigos-cientificos__text__title a {
                    color: ${props => props.theme.colors.textBlack};
                    font-size: ${props => props.theme.fontSize.title};
                    font-weight:bolder;
                    text-decoration:none;


                    &:hover{
                        text-decoration:underline
                    }
                }

                .artigos-cientificos__text__description p {
                    color: ${props => props.theme.colors.textBlack};
                    font-size: ${props => props.theme.fontSize.medium};
                    padding: 6px 0px 16px 0px;
                }

                .artigos-cientificos__text__buttons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        color: ${props => props.theme.colors.primary};
                        font-size: ${props => props.theme.fontSize.small};
                        border: 2px solid ${props => props.theme.colors.primary};
                        border-radius: ${props => props.theme.borderRadius};
                        font-weight: 600;
                        padding: 2px 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    a {
                        color: ${props => props.theme.colors.primary};
                        font-size: ${props  => props.theme.fontSize.small};
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        text-decoration: none;

                        svg {
                            font-size: 16px;
                            margin-left: 8px;
                        }
                    }
                }

                .artigos-cientificos__text__buttons--not-default {
                    p {
                        border: 2px solid ${props => props.theme.colors.secondary};
                        color: ${props => props.theme.colors.secondary};
                    }
                }
            }
        }
    }


    /* Adaptação Media Query  */
    ${props => props.theme.media.desktop} {
        .artigos-cientificos__title {
            margin: 15px 0px;

            .artigos-cientificos__title--text {
                width: 150px;
                
                p {
                    font-size: ${props => props.theme.fontSizeDesktop.large};
                    position: absolute;
                }
            }
        }

        .artigos-cientificos__container {
            margin: 0px;

            .artigos-cientificos__individual {
                margin: 0px 0px 10px 0px;
                padding: 0px;
                display: flex;

                .artigos-cientificos__image {
                    display: flex;

                    img {
                        border-top-left-radius: ${props => props.theme.borderRadius};
                        border-bottom-left-radius: ${props => props.theme.borderRadius};
                        display: flex;
                        width: 280px;
                        height: 180px;
                        object-fit: cover;
                    }                
                }

                .artigos-cientificos__text {
                    padding: 15px;

                    .artigos-cientificos__text__title {
                        
                        a {font-size: ${props => props.theme.fontSizeDesktop.medium}}

                        text-decoration:none;


                        &:hover{
                            text-decoration:underline
                        }
                    }

                    .artigos-cientificos__text__description {
                        width:250px;
                        p {font-size: ${props => props.theme.fontSizeDesktop.small}}
                    }

                    .artigos-cientificos__text__buttons {
                        height: 15%;

                        a {
                            color: ${props => props.theme.colors.secondary};
                            font-size: ${props => props.theme.fontSizeDesktop.small};
                        }
                    }
                }
            }
        }
    }
`;