import styled from "styled-components";

export const NoticiasStyle = styled.div`
    .noticias__container {
        margin-bottom: 20px;

        .noticias__individual {
            border-radius: ${props => props.theme.borderRadius};
            border: 1px solid ${props => props.theme.colors.border};
            margin: ${props => props.theme.margin};
            background-color: white;
            padding: 15px;

            .noticias__image {display: none}

            .noticias__text {
                .noticias__text__title a {
                    color: ${props => props.theme.colors.textBlack};
                    font-size: ${props => props.theme.fontSize.large};
                    text-decoration:none;

                    &:hover{
                        text-decoration:underline
                    }
                }

                .noticias__text__description p {
                    color: ${props => props.theme.colors.textBlack};
                    font-size: ${props => props.theme.fontSize.medium};
                    padding: 6px 0px 16px 0px;
                }

                .noticias__text__buttons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        color: ${props => props.theme.colors.textWhite};
                        font-size: ${props => props.theme.fontSize.small};
                        background-color: ${props => props.theme.colors.primary};
                        border-radius: ${props => props.theme.borderRadius};
                        font-weight: 500;
                        padding: 2px 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-rigth:.2rem
                    }

                    a {
                        color: ${props => props.theme.colors.primary};
                        font-size: ${props  => props.theme.fontSize.small};
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        text-decoration: none;

                        svg {
                            font-size: 16px;
                            margin-left: 8px;
                        }
                    }
                }

                .noticias__text__buttons--not-default {
                    p {
                        background-color: ${props => props.theme.colors.secondary};
                    }
                }
            }
        }
    }


    /* Adaptação Media Query */
    ${props => props.theme.media.desktop} {
        .noticias__container {
            margin: 0px;

            .noticias__individual {
                margin: 0px 0px 10px 0px;
                padding: 0px;
                display: flex;

                .noticias__image {
                    display: flex;

                    img {
                        border-top-left-radius: ${props => props.theme.borderRadius};
                        border-bottom-left-radius: ${props => props.theme.borderRadius};
                        display: flex;
                        width: 280px;
                        height: 180px;
                        object-fit: cover;
                    }                
                }

                .noticias__text {
                    padding: 15px;

                    .noticias__text__title {
                        height: 30%;

                        a {font-size: ${props => props.theme.fontSizeDesktop.large}}
                    }

                    .noticias__text__description {
                        height: 50%;

                        p {font-size: ${props => props.theme.fontSizeDesktop.medium}}
                    }

                    .noticias__text__buttons {
                        height: 20%;

                        a {
                            color: ${props => props.theme.colors.secondary};
                            font-size: ${props => props.theme.fontSizeDesktop.small};
                        }
                    }
                }
            }
        }
    }
`;