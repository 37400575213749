// Styles
import { SearchStyle } from "./styles";

// Icons
import { AiOutlineSearch } from 'react-icons/ai';


const Search: React.FC = () => {
    return (
        <SearchStyle>
            <input placeholder="Buscar conteúdo, tag, eventos..." />

            <button type="button">
                <AiOutlineSearch />
            </button>
        </SearchStyle>
    )
}

export default Search;