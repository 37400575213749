import Carousel from "react-elastic-carousel";

// Styles
import { PrincipaisNoticiasStyle, CarouselItem } from "./styles";

// Icons
import { MdArrowRightAlt } from "react-icons/md";

function PrincipaisNoticias({ notices }: any) {
  return (
    <PrincipaisNoticiasStyle>
      {/* Notícias para Mobile */}
      <Carousel itemsToShow={1} isRTL={false} showArrows={false}>
        {Object.keys(notices).length > 0
          ? notices.results.map((noticia: Noticias) => (
              <CarouselItem key={noticia.id}>
                <img src={noticia.img} alt="" />

                <div className={"carousel-item__category"}>
                  {noticia.categoria.map((categoria: any) => {
                    return (
                      <p
                        className={
                          categoria.nome === "Carcinicultura"
                            ? "carousel-item__category--not-default"
                            : ""
                        }
                      >
                        {categoria.nome}
                      </p>
                    );
                  })}
                </div>

                <div className={"carousel-item__text"}>
                  <div className="carousel-item__text__title">
                    <a href={`/noticia/${noticia.id}`}>{noticia.titulo}</a>
                  </div>

                  <div className="carousel-item__text__saiba-mais">
                    <a href={`/noticia/${noticia.id}`}>
                      SAIBA MAIS
                      <MdArrowRightAlt />
                    </a>
                  </div>
                </div>
              </CarouselItem>
            ))
          : null}
      </Carousel>

      {/* Notícias para Desktop */}

      <div className="principal-noticia__container">
        {Object.keys(notices).length > 0 ? (
          <div className="principal-noticia__noticia-principal">
            <img src={notices.results[0].img} alt="" />
            <div className={"carousel-item__category"}>
              {notices.results[0].categoria.map((categoria: any) => {
                return (
                  <p
                    className={
                      categoria.nome === "Carcinicultura"
                        ? "carousel-item__category--not-default"
                        : ""
                    }
                  >
                    {categoria.nome}
                  </p>
                );
              })}
            </div>
            <div className={"carousel-item__text"}>
              <div className="carousel-item__text__title">
                <a href={`/noticia/${notices.results[0].id}`}>
                  {notices.results[0].titulo}
                </a>
              </div>

              <div className="carousel-item__text__saiba-mais">
                <a href={`/noticia/${notices.results[0].id}`}>
                  SAIBA MAIS
                  <MdArrowRightAlt />
                </a>
              </div>
            </div>
          </div>
        ) : null}

        {Object.keys(notices).length > 0 ? (
          <aside className="principal-noticia__noticias-secundarias">
            {notices.results.slice(1, 3).map((notice: any) => {
              return (
                <div>
                  <img src={notice.img} alt={notice.title} />
                  <div className={"carousel-item__category"}>
                    {notice.categoria.map((categoria: any) => {
                      return <p>{categoria.nome}</p>;
                    })}
                  </div>
                  <div className={"carousel-item__text"}>
                    <div className="carousel-item__text__title">
                      <h3>{notice.titulo}</h3>
                    </div>

                    <div className="carousel-item__text__saiba-mais">
                      <a href={`/noticia/${notice.id}`}>
                        SAIBA MAIS
                        <MdArrowRightAlt />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </aside>
        ) : null}
      </div>
    </PrincipaisNoticiasStyle>
  );
}

export default PrincipaisNoticias;
