


// Styles
import { NoticiasMaisLidasStyle } from "./styles";


function NoticiasMaisLidas({ notices }:any) {
  return (
    <NoticiasMaisLidasStyle>
      <div className="noticias__mais-lidas__title">
        <h4>Notícias mais lidas</h4>
      </div>

      {Object.keys(notices).length > 0
        ? notices.results.slice(0, 4).map((noticia: NoticiasMaisLidas) => (
            <div key={noticia.id} className="noticias__mais-lidas__text">
              <a href={`/noticia/${noticia.id}`}>{noticia.titulo}</a>
            </div>
          ))
        : null}
    </NoticiasMaisLidasStyle>
  );
}

export default NoticiasMaisLidas;
