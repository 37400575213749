import styled from "styled-components";

export const FooterStyle = styled.footer`
  margin-top: 40px;

  .footer__title {
    margin: ${(props) => props.theme.margin};
    height: 25px;

    .footer__title--text {
      border-bottom: 2px solid ${(props) => props.theme.colors.primary};
      width: 145px;
      height: 25px;

      p {
        font-size: ${(props) => props.theme.fontSize.large};
        color: #4d4d4d;
        font-weight: 600;
      }
    }
  }

  .footer__parceiros {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    justify-content: center;
    align-items: center;
    margin: 25px 0px;

    img {
      margin: 0 auto;
    }
    .logo__embrapa {
      width: 120px;
    }
    .logo__aquabit {
      width: 145px;
    }
    .logo__sebrae {
      width: 115px;
    }
    .logo__codevasf {
      width: 130px;
    }
  }

  .description {
    width: 100%;
    padding: 0 100px;
    padding-top: 100px;
    padding-bottom: 50px;
    margin-top: -5rem;
    background-image: url("/images/footer/onda2.png");

    background-repeat: no-repeat;
    background-position-y: 6%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    @media (max-width: 800px) {
      background-size: cover;
      flex-direction: column;
      align-items: center;
      margin-top: -10rem;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: start;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          justify-content: center;
        }
      }
      h3 {
        font-size: 30px;
      }
      h4 {
        font-size: 20px;
      }
      h4,
      h3,
      p,
      a {
        color: white;
        text-decoration: none;
        margin-bottom: 2rem;
      }
      ul {
        list-style: none;
        color: white;
        li {
          margin-bottom: 1rem;
        }
      }
      img {
        margin-right: 1rem;
      }
      @media (max-width: 800px) {
        
        padding-top: 2rem;
        h4,
        h3,
        p,
        a {
          color: white;
          text-decoration: none;
          margin-bottom: 0;
        }
        div {
          margin-top: 1rem;
          justify-content: center;
          align-items: center !important;
        }
      }
    }
  }

  /* Adaptação Media Query */
  ${(props) => props.theme.media.desktop} {
    .description {
      width: 100%;
      padding: 0 100px;
      padding-top: 100px;
      padding-bottom: 50px;
      margin-top: -5rem;
      background-image: url("/images/footer/onda2.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: 6%;
      display: flex;
      justify-content: space-between;
      align-items: start;
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          &:last-child {
            justify-content: center;
          }
        }
        h3 {
          font-size: 30px;
        }
        h4 {
          font-size: 20px;
        }
        h4,
        h3,
        p,
        a {
          color: white;
          text-decoration: none;
          margin-bottom: 2rem;
        }
        ul {
          list-style: none;
          color: white;
          li {
            margin-bottom: 1rem;
          }
        }
        img {
          margin-right: 1rem;
        }
      }
    }
    .conheca-aquabit__logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .footer__title {
      margin: 0px;
    }
    .footer__parceiros {
      display: flex;

      .logo__embrapa {
        width: 160px;
      }
      .logo__aquabit {
        width: 175px;
      }
      .logo__sebrae {
        width: 130px;
      }
      .logo__codevasf {
        width: 155px;
      }
    }
  }
`;
