import CardDropdown from "components/Cards/CardDropdown/CardDropdown";

// Styles
import { CardsStyle } from "./styles";
import { Container } from "styles/Global";

const Cards: React.FC = () => {
  return (
    <CardsStyle>
      <Container>
        <CardDropdown
          title="O que é a ComunidadeAqua?"
          description="É o canal de comunicação e busca de conhecimento para todos os interessados na cadeia produtiva da Aquicultura."
        />

        <CardDropdown
          title="Como faço para participar da ComunidadeAqua?"
          description="Através de solicitação pelo formulário na plataforma, WhatsApp ou nossas redes sociais."
        />

        <CardDropdown
          title="O que é o BatePapo Aqua?"
          description="Reunião remota (online) mensal, para compartilhamento de conhecimento, através de produtores, técnicos e fornecedores de soluções aquícola."
        />

        <CardDropdown
          title="Quem são os técnicos parceiros da ComunidadeAqua?"
          description="São técnicos que se disponibiliza em compartilhar conhecimento através dos canais da comunidade como também, divulgar a comunidade e conduzir os bate papos, conforme alinhamentos com a organização."
        />

        <CardDropdown
          title="Como faço para ser técnico parceiro da ComunidadeAqua?"
          description="Através de solicitação pelo WhatsApp ou nossas redes sociais."
        />
      </Container>
    </CardsStyle>
  );
};

export default Cards;
