import styled from "styled-components";
import { shade } from "polished";

export const ConhecaAquabitStyle = styled.div`
    background-color: ${props => props.theme.colors.primary};    
    width: 100%;
    height: 56px;    
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 20px 0px;

    .conheca-aquabit__logo {display: none}

    .conheca-aquabit__text {
        display: flex;
        align-items: center;
        justify-content: center;

        .conheca-aquabit__text--mobile {
            color: ${props => props.theme.colors.textWhite};
            font-size: ${props => props.theme.fontSize.small};
            width: 115px;
            text-align: left;
            font-weight: bold;
        }

        .conheca-aquabit__text--desktop {display: none}
    }

    .conheca-aquabit__image {
        background-image: url('/images/cards/card-conheca-aquabit.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-right: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;

        p {
            color: ${props => props.theme.colors.textWhite};
            font-weight: bold;
        }

        a {
            border-radius: ${props => props.theme.borderRadius};
            color: ${props => props.theme.colors.primary};
            transition: ${props => props.theme.transition};
            border: none;
            width: 72px;
            height: 21px;
            background-color: #FFFFFF;
            font-size: 10px;
            font-weight: bold;
            cursor: pointer;
            display:flex;
            justify-content:center;
            align-items:center;
            &:hover {
                background-color: ${shade(0.2, '#FFFFFF')};
            }
            text-decoration:none;
        }
    }


    /* Adaptação Media Query */
    ${props => props.theme.media.desktop} {
        border-radius: ${props => props.theme.borderRadius};
        grid-template-columns: 2fr 7fr 3fr;

        .conheca-aquabit__logo {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .conheca-aquabit__text {
            .conheca-aquabit__text--mobile {display: none}

            .conheca-aquabit__text--desktop {
                display: flex;
                color: ${props => props.theme.colors.textWhite};
                font-size: ${props => props.theme.fontSize.large};                
                text-align: left;
                font-weight: bold;
            }
        }

        .conheca-aquabit__image {
            border-top-right-radius: ${props => props.theme.borderRadius};
            border-bottom-right-radius: ${props => props.theme.borderRadius};
            padding-right: 10px;
            align-items: center;
            justify-content: flex-end;
            flex-direction: row;

            p {display: none}

            a {
                color: ${props => props.theme.colors.textWhite};
                background-color: ${props => props.theme.colors.secondary};
                font-size: ${props => props.theme.fontSizeDesktop.small};
                width: 114px;
                height: 33px;
                display:flex;
                justify-content:center;
                align-items:center;
                &:hover {
                    background-color: ${props => shade(0.2, props.theme.colors.secondary)};
                }
                text-decoration:none;
            }
        }
    }
`;