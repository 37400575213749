// Styles
import { CategoriasStyle } from "./styles";
import { Container } from "styles/Global";


const Categorias: React.FC = () => {
    return (
        <CategoriasStyle>
            <Container>
                Categorias!
            </Container>
        </CategoriasStyle>
    )
}

export default Categorias;