// Styles
import { ConhecaAquabitStyle } from "./styles";
import { Container } from "styles/Global";


const ConhecaAquabit: React.FC = () => {
    return (
        <ConhecaAquabitStyle>
            <Container>
                Conheça Aquabit!
            </Container>
        </ConhecaAquabitStyle>
    )
}

export default ConhecaAquabit;