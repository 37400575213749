import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;        
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
    }

    body {
        background-color: ${props => props.theme.colors.background};
        font-family: 'Roboto', sans-serif;    
    }
`;

export const Container = styled.div`
    width: 100%;    


    // Media Query
    ${props => props.theme.media.desktop} {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        width: 1185px;
    }
`;